import React, { useState } from 'react';
import '../Estilos/principal.scss';

import logo from '../Graficos/Imagenes/logotipo-torres-x.svg';
import logo1 from '../Graficos/Imagenes/logotipo\ invertido.svg';
import imagenPrincipal from '../Graficos/Imagenes/principal-imagen.jpg';
import tiposLlave from'../Graficos/Imagenes/tipos-llave.jpg';

import llaveAbloy from '../Graficos/Imagenes/llave-abloy.jpg';
import llavePuntos from '../Graficos/Imagenes/llave-puntos.jpg';
import llaveTubular from '../Graficos/Imagenes/llave-tubular.jpg';
import llaveTetra from '../Graficos/Imagenes/llave-tetra.png';


const Principal = () => {

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <main className="seccion-principal" id="inicio-section">
            <div id="tipos-llave-seccion" className="contenedor-principal-imagenes">
                <div className="contenedor-principal-imagen">
                    <img src={imagenPrincipal} alt="llavero en la pared"></img>
                </div>
                <div className="contenedor-principal-logo-1">
                    <div className="logo-1">
                        <img src={logo1} alt="logo de Torres X"></img>
                    </div>
                </div>
                <div className="contenedor-principal-tipos-llave">
                    <div className="imagen-tipos-llave">
                        <img src={tiposLlave} alt="Llave en fondo negro"></img>
                    </div>
                    <div  className='contenedor-principal-llave'
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        {dropdownVisible && (
                            <div className="contenedor-dropdown-llave">
                                <div className="dropdown-llave">
                                    <div className="llave">
                                        <h5>Llave abloy</h5>
                                        <img src={llaveAbloy} alt="Llave abloy"></img>
                                    </div>
                                    <div className="llave">
                                        <h5>Llave tubular</h5>
                                        <img src={llaveTubular} alt="Llave tubular"></img>
                                    </div>
                                    <div className="llave">
                                        <h5>Llave de puntos</h5>
                                        <img src={llavePuntos} alt="Llave de puntos"></img>
                                    </div>
                                    <div className="llave">
                                        <h5>Llave tetra</h5>
                                        <img src={llaveTetra} alt="Llave tetra"></img>
                                    </div>
                                </div>
                            </div>
                        )}
                        <p className='dropdown-texto-llave'>Algunos tipos <br></br>de llaves</p>
                    </div>
                </div>
            </div>
            <div className="contenedor-principal-texto">
                <div className="contenedor-principal-logo-2">
                    <img src={logo} alt="Logo de Torres X"></img>
                </div>
                <h2><strong>Tu Seguridad, Nuestro Trabajo</strong></h2>
                <p id="servicios-section">Nos dedicamos a proteger lo que más valoras. Ofrecemos servicios de cerrajería confiables y accesibles para garantizar tu tranquilidad y seguridad.</p>
                <div className="contenedor-principal-botones">
                    <button className='boton-1' onClick={() => scrollToSection('nosotros-section')}>Nosotros</button>
                    <button className='boton-2' onClick={() => scrollToSection('contacto-section')}>Contactar</button>
                </div>
            </div>
        </main>
    );
};

export default Principal;