import Raiz from "./Raiz/Raiz";


function App() {
  return (
    <div className>
      <Raiz/>
    </div>
  );
}

export default App;
