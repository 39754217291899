import React, { useState } from 'react';
import '../Estilos/servicios.scss';
import imagenServicios from '../Graficos/Imagenes/servicios-imagen.jpg'

const Servicios = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    return (
        <section className='seccion-servicios'>
            <div className="contenedor-servicios-titulo">
                <h3>Servicios</h3>
            </div>
            <div className="contenedor-servicios-opciones">
                <div className="fila-servicios">
                    <div
                        className="contenedor-servicios-opcion"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span>Llaves <br></br>y duplicados</span>
                        {dropdownVisible && (
                            <div className="contenedor-dropdown">
                                <h5>Llaves y duplicados</h5>
                                <p>Ofrecemos servicios de duplicado de llaves para asegurar tu tranquilidad en casa, la oficina o tu automóvil.</p>
                            </div>
                        )}
                    </div>
                    <div
                        className="contenedor-servicios-opcion"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span>Aperturas</span>
                        {dropdownVisible && (
                            <div className="contenedor-dropdown">
                                <h5>Aperturas</h5>
                                <p>Nuestro equipo está listo para resolver tus necesidades de acceso de manera eficiente y segura.</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="fila-servicios">
                    <div
                        className="contenedor-servicios-opcion"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span>Mantenimiento</span>
                        {dropdownVisible && (
                            <div className="contenedor-dropdown">
                                <h5>Mantenimiento</h5>
                                <p>Optimizamos la seguridad y funcionalidad de tus cerraduras con el mantenimiento especializado.</p>
                            </div>
                        )}
                    </div>
                    <div id="nosotros-section"
                        className="contenedor-servicios-opcion"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span>Reparación e instalaciones</span>
                        {dropdownVisible && (
                            <div className="contenedor-dropdown">
                                <h5>Reparación e instalaciones</h5>
                                <p>Desde reparar cerraduras hasta instalar sistemas de seguridad, estamos listo para atender tus necesidades.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="imagen-servicios-desktop">
                <img src={imagenServicios} alt="Imagen de servicios"></img>
            </div>
        </section >

    );
};

export default Servicios;
