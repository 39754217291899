import React, { useState, useEffect, useRef } from 'react';
import '../Estilos/encabezado.scss';
import telefono from '../Graficos/Iconos/telefono.png'

const Encabezado = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    if (menuVisible) {
      document.body.addEventListener('click', closeMenu);
    }

    return () => {
      document.body.removeEventListener('click', closeMenu);
    };
  }, [menuVisible]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setMenuVisible(false);
    }
  };

  const phoneNumber = '+523318336644';

  const handleClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <header>
      <div className="contenedor-encabezado-nombre">
        <h1>Cerrajería Torres X</h1>
      </div>
      <div className="contenedor-encabezado-navbar-horizontal">
        <ul>
            <li> <a href="https://wa.me/+523318336644" target="_blank" rel="noopener noreferrer">Whatsapp</a></li>
            <li onClick={() => scrollToSection('inicio-section')}>Inicio</li>
            <li onClick={() => scrollToSection('servicios-section')}>Servicios</li>
            <li onClick={() => scrollToSection('nosotros-section')}>Nosotros</li>
            <li onClick={() => scrollToSection('contacto-section')}>Contacto</li>
        </ul>
      </div> 
      <div className="contenedor-encabezado-boton">
      <button onClick={handleClick}>
        <img src={telefono} alt="icono telefono" />
        <span>3318336644</span>
      </button>
      </div>
      <div className="contenedor-encabezado-navbar">
        <nav ref={menuRef} className={menuVisible ? 'menu-visible' : ''}>
          <div className="toggle-button" onClick={toggleMenu}>
            <span>☰</span>
          </div>
          <ul>
            <li> <a href="https://wa.me/+523318336644" target="_blank" rel="noopener noreferrer">Whatsapp</a></li>
            <li onClick={() => scrollToSection('inicio-section')}>Inicio</li>
            <li onClick={() => scrollToSection('servicios-section')}>Servicios</li>
            <li onClick={() => scrollToSection('nosotros-section')}>Nosotros</li>
            <li onClick={() => scrollToSection('contacto-section')}>Contacto</li>
          </ul>
        </nav>
      </div> 
    </header>
  );
};

export default Encabezado;
