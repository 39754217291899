import React from 'react';
import '../Estilos/clientes.scss';
import imagenClientesResidencial from '../Graficos/Imagenes/clientes-residencial.jpg';
import imagenClientesAutomotriz from '../Graficos/Imagenes/clientes-automotriz.jpg';
import imagenClientesEmpresarial from '../Graficos/Imagenes/clientes-empresarial.jpg';

const Clientes = () => {
    return (
        <section className='seccion-clientes' id="clientes-section">
            <div className="contenedor-clientes-texto">
                <div className="titulo-clientes">
                    <h3>Nuestros Clientes</h3>
                </div>
                <div className="texto-clientes">
                    <p>Destacamos como líder en cerrajería. Hemos perfeccionado nuestras habilidades para ofrecer servicios de calidad, desde aperturas de emergencia hasta instalación y mantenimiento constante.</p>
                </div>
            </div>
            <div className="contenedor-clientes-imagenes">
                <div className="contenedor-imagen">
                    <div className="clientes-imagen">
                        <img src={imagenClientesResidencial} alt="Clientes residencial" />
                    </div>
                    <div className="clientes-descripcion">
                        <p>Residencial</p>
                        <div className="dropdown-content">
                            <h5>Residencial</h5>
                            <p>Amaestramientos, repación, venta y aperturas de puertas, cambio de combinanción, instalación desde cero con soldadura y más.</p>
                        </div>
                    </div>
                </div>
                <div className="contenedor-imagen">
                    <div className="clientes-imagen">
                        <img src={imagenClientesAutomotriz} alt="Clientes automotriz" />
                    </div>
                    <div className="clientes-descripcion">
                        <p>Automotriz</p>
                        <div className="dropdown-content">
                            <h5>Automotriz</h5>
                            <p>Apertura de vehículos, copias de llaves, programación de llaves electrónicas, servicios rápidos y profesionales en movilidad.</p>
                        </div>
                    </div>
                </div>
                <div className="contenedor-imagen">
                    <div className="clientes-imagen">
                        <img src={imagenClientesEmpresarial} alt="Clientes empresarial" />
                    </div>
                    <div className="clientes-descripcion" id="contacto-section">
                        <p>Empresarial</p>
                        <div className="dropdown-content">
                            <h5>Empresarial</h5>
                            <p>Control de accesos, sistemas de seguridad avanzados, cerraduras electrónicas, llaves maestras y atención especializada para empresas.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Clientes;
