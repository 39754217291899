import React from 'react';
import '../Estilos/footer.scss';
import facebookIcono from '../Graficos/Iconos/facebook.png';
import llamadaIcono from '../Graficos/Iconos/llamada.png';
import mailIcon from '../Graficos/Iconos/mail.png';
import whatsappIcon from '../Graficos/Iconos/whatsapp.png';
import logoInvertido from '../Graficos/Imagenes/logotipo\ invertido.svg';

const Footer = () => {

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const phoneNumber = '+523318336644';

    const handleClick = () => {
      window.location.href = `tel:${phoneNumber}`;
    };
  

    return (
        <footer className='seccion-footer'>
            <div className="contenedor-footer-titulo">
                <div className="footer-titulo">
                    <h3>Torres X</h3>
                </div>
                <div className="footer-iconos">
                    <a href="https://www.facebook.com/profile.php?id=100076175225034&locale=ms_MY" target="_blank" rel="noopener noreferrer">
                        <img src={facebookIcono} alt="Facebook" />
                    </a>
                    <a href="https://wa.me/+523318336644" target="_blank" rel="noopener noreferrer">
                        <img src={whatsappIcon} alt="WhatsApp" />
                    </a>
                    <a href={`mailto:"cerrajeriatorresx.contacto@gmail.com"`} target="_blank" rel="noopener noreferrer">
                        <img src={mailIcon} alt="Email" />
                    </a>
                    <a onClick={handleClick}>
                        <img src={llamadaIcono} alt="Instagram" />
                    </a>
                </div>
                <div className="footer-texto">
                    <p>Confianza | Profesionalismo | Calidad</p>
                </div>
            </div>
            <hr></hr>
            <div className="contenedor-footer-medio">
                <div className="footer-columna-1">
                    <span onClick={() => scrollToSection('inicio-section')}>Inicio</span>
                    <span onClick={() => scrollToSection('servicios-section')}>Servicios</span>
                    <span onClick={() => scrollToSection('nosotros-section')}>Nosotros</span>
                    <span onClick={() => scrollToSection('clientes-section')}>Clientes</span>
                    <span><a href="https://wa.me/+523313327393">Solicitar</a></span>
                </div>
                <div className="footer-columna-2">
                    <span className="btn-tipos-llave" onClick={() => scrollToSection('tipos-llave-seccion')}>Tipos de llaves</span>
                    <span onClick={() => scrollToSection('servicios-section')}>Llaves y duplicados</span>
                    <span onClick={() => scrollToSection('servicios-section')}>Mantenimiento</span>
                    <span onClick={() => scrollToSection('servicios-section')}>Reparaciones e instalaciones</span>
                </div>
                <div className="footer-columna-3">
                    <img src={logoInvertido} onClick={() => scrollToSection('inicio-section')} />
                </div>
            </div>
            <hr></hr>
            <div className="contenedor-footer-bajo">
                <span>© 2023 Torres X  | Todos los derechos reservados </span>
            </div>
        </footer>
    );
};

export default Footer;
