import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { Element } from 'react-scroll';
import '../Estilos/nosotros.scss';
import imagenNosotros from '../Graficos/Imagenes/nosotros-imagen.jpg'
import imagenNosotrosMediana from '../Graficos/Imagenes/nosotros-imagen-mediana.jpg'
import imagenNosotrosGrande from '../Graficos/Imagenes/nosotros-imagen-grande.jpg'

const Nosotros = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.querySelector('.seccion-nosotros');
            if (section) {
                const rect = section.getBoundingClientRect();
                const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
                setIsVisible(isVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section name="nosotros" className='seccion-nosotros'>
            <div className='contenedor-nosotros-principal'>
                <div className='contenedor-nosotros-titulo'>
                    <h3>Tenemos 18 Años De Experiencia</h3>
                </div>
                <div className="contenedor-nosotros-imagen">
                <picture>
                        <source media="(min-width: 0px) and (max-width: 768px)" srcSet={imagenNosotros} />
                        <source media="(min-width: 769px) and (max-width: 1279px)" srcSet={imagenNosotrosMediana} />
                        <source media="(min-width: 1280px)" srcSet={imagenNosotrosGrande} />
                        <img className="foto" src={imagenNosotros} alt="Foto de llaves" />
                    </picture>
                </div>
                <div className="contenedor-nosotros-contenido">
                    <h3 className="contenido-titulo-2">Tenemos 18 Años De Experiencia</h3>
                    <p>Destacamos como líder en cerrajería. Hemos perfeccionado nuestras habilidades para ofrecer servicios de calidad, desde aperturas de emergencia hasta instalación y mantenimiento de cerraduras avanzadas. Nos comprometemos a ofrecer soluciones efectivas, respaldadas por la confianza de clientes que valoran nuestra excelencia constante.</p>
                    <button><a href="https://maps.app.goo.gl/HwFK9gHNC8WUzcbR6" target="_blank">Ubicación en el mapa</a></button>
                </div>
            </div>
            <div className="contenedor-nosotros-datos">
                <div className='nosotros-datos disponibles'>
                    <div className='datos-numero'>
                        <CountUp start={0} end={isVisible ? 25 : 0} duration={10} separator="," />
                    </div>
                    <div className='datos-texto'>Servicios disponibles</div>
                    <hr className='linea-nosotros'></hr>
                </div>
                <div className='nosotros-datos'>
                    <div className='datos-numero'>
                        <CountUp start={0} end={isVisible ? 12346 : 0} duration={10} separator="," />
                    </div>
                    <div className='datos-texto'>Clientes aproximados</div>
                    <hr className='linea-nosotros'></hr>
                </div>
                <div className='nosotros-datos'>
                    <div className='datos-numero'>
                        <CountUp start={0} end={isVisible ? 2321 : 0} duration={10} separator="," />
                    </div>
                    <div className='datos-texto'>Puertas aperturadas</div>
                    <hr className='linea-nosotros'></hr>
                </div>
                <div className='nosotros-datos'>
                    <div className='datos-numero'>
                        <CountUp start={0} end={isVisible ? 52971 : 0} duration={10} separator="," />
                    </div>
                    <div className='datos-texto' id='clientes-section'>Llaves duplicadas</div>
                </div>
            </div>
        </section>
    );
};

export default Nosotros;
