import React from 'react';
import '../Estilos/contacto.scss';
import mastercardIcono from '../Graficos/Iconos/mastercard.png';
import visaIcono from '../Graficos/Iconos/visa.png';
import americanIcon from '../Graficos/Iconos/american-express.png';
import imagenContacto from '../Graficos/Imagenes/contacto-imagen.jpg';
import imagenContacto1 from '../Graficos/Imagenes/contacto-imagen-1.jpg';
import imagenContacto2 from '../Graficos/Imagenes/contacto-imagen-2.jpg';

const Contacto = () => {

    return (
        <section className='seccion-contacto' >
            <div className="contenedor-contacto-imagenes-1">
                <img src={imagenContacto} alt="imagen de contacto"></img>
            </div>
            <div className="contenedor-contacto-imagenes-2">
                <div className="imagen-contacto-3"></div>
                <img className="imagen-contacto-2" src={imagenContacto2} alt="imagen de contacto"></img>
                <img className="imagen-contacto-1" src={imagenContacto1} alt="imagen de contacto"></img>
            </div>
            <div className="contenedor-contacto-texto">
                <h3>Contacto</h3>
                <p>Para satisfacer tus necesidades de cerrajería de manera eficiente y confiable, te invitamos a ponerte en contacto con nosotros.</p>
                <p><b>Teléfono:</b> 33 1833 6644</p>
                <p><b>Ubicación:</b> San Juan 203 Tepeyac, 45150 Zapopan, Jal.</p>
                <p><b>Horario:.</b></p>
                <ul>
                    <li>L, M, I, V: 10 a.m. - 7 p.m. </li>
                    <li>J: 10 a.m. - 5 p.m. </li>
                    <li>S y D: sólo servicios de emergencias y programados.</li>
                    <li><b>Emergencias las 24 horas.</b></li>
                </ul>
                <p><b>Métodos de pago:</b></p>
                <ul>
                    <li>Efectivo</li>
                    <li>Transferencia</li>
                    <li >Tarjeta
                        <div className="contacto-metodos">
                            <div className="contacto-metodos-iconos">
                                <img src={mastercardIcono} alt="Mastercard" />
                                <img src={visaIcono} alt="Visa" />
                                <img src={americanIcon} alt="American Express" />
                            </div>
                        </div>
                    </li>
                </ul>

                <p>También facturamos</p>
                <button><a href="https://wa.me/+523318336644">Solicitar servicio</a></button>
            </div>
        </section>
    );
};

export default Contacto;
